<template>
  <m-page-header title="商品列表" />
  <div class="view">
    <div class="serach_input">
      <el-input placeholder="请输入商品名称或编号" size="medium" v-model="prams.keyword">
        <template #append>
          <el-button icon="el-icon-search" size="medium" @click="getList"></el-button>
        </template>
      </el-input>
    </div>
    <div class="list-wrap">
      <m-table
        :data="dataList"
        :loading="loading"
        :pagination="{
          total: prams.total,
          pageSize: prams.page_size,
          currentPage: prams.page,
        }"
        @currentChange="handleCurrentChange"
      >
        <template #empty>
          <el-empty description="暂无数据"></el-empty>
        </template>
        <el-table-column label="商品">
          <template #default="goods">
            <div class="goods-wrap">
              <m-goods
                :img="goods.row.image"
                :href="goods.row.goods_url"
                :detail="[
                  {
                    text: goods.row.base_name,
                    type: 'link,bold',
                    href: goods.row.goods_url,
                  },
                  { text: goods.row.category_name },
                  { text: goods.row.codeno },
                ]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template #default="status">
            <el-tag v-if="status.row.sync_status === 1">已同步</el-tag>
            <el-tag type="danger" v-else-if="status.row.sync_status === -1">同步失败</el-tag>
            <el-tag type="danger" v-else-if="status.row.sync_status === -2"
              >错误次数超过限制</el-tag
            >
            <el-tag type="warning" v-else-if="status.row.sync_status === 0">待同步</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="错误信息" prop="remark"></el-table-column>
        <el-table-column label="同步时间" prop="syn_time"></el-table-column>
      </m-table>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { goodsList } from '../api/GoodsList';
import MGoods from '@/components/goods/Goods';
export default {
  name: 'GoodsList',
  components: {
    MGoods,
  },
  setup() {
    const prams = ref({
      page: 1,
      total: 0,
      page_size: 10,
      keyword: '',
    });
    const dataList = ref([]);
    const loading = ref(true);
    const getList = async () => {
      const { data } = await goodsList(
        { page: prams.value.page, page_size: prams.value.page_size, keyword: prams.value.keyword },
        loading,
      );
      dataList.value = data.list;
      prams.value.total = Number(data.total);
    };
    const handleCurrentChange = val => {
      prams.value.page = val;
      getList();
    };
    return {
      prams,
      dataList,
      getList,
      handleCurrentChange,
      loading,
    };
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped lang="scss">
.view {
  padding: 15px;
  .serach_input {
    width: 280px;
  }
  .list-wrap {
    margin-top: 15px;
    .goods-wrap {
      max-width: 500px;
    }
  }
}
</style>
